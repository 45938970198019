/* Staff Light */
@font-face {
	font-family: 'Staff';
	src:
		url('./Staff-Light.woff2') format('woff2'),
		url('./Staff-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

/* Staff Regular */
@font-face {
	font-family: 'Staff';
	src:
		url('./Staff-Regular.woff2') format('woff2'),
		url('./Staff-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* Staff Regular Italic */
@font-face {
	font-family: 'Staff';
	src:
		url('./Staff-Italic.woff2') format('woff2'),
		url('./Staff-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

/* Staff Medium */
@font-face {
	font-family: 'Staff';
	src:
		url('./Staff-Medium.woff2') format('woff2'),
		url('./Staff-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

/* Staff SemiBold */
@font-face {
	font-family: 'Staff';
	src:
		url('./Staff-SemiBold.woff2') format('woff2'),
		url('./Staff-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

/* Staff Condensed Regular */
@font-face {
	font-family: 'Staff Condensed';
	src:
		url('./StaffCondensed-Regular.woff2') format('woff2'),
		url('./StaffCondensed-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* Staff Condensed Bold */
@font-face {
	font-family: 'Staff Condensed';
	src:
		url('./StaffCondensed-Bold.woff2') format('woff2'),
		url('./StaffCondensed-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

/* Staff Wide Light */
@font-face {
	font-family: 'Staff Wide';
	src:
		url('./StaffWide-Light.woff') format('woff2'),
		url('./StaffWide-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

/* Staff Wide Regular */
@font-face {
	font-family: 'Staff Wide';
	src:
		url('./StaffWide-Regular.woff2') format('woff2'),
		url('./StaffWide-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* Staff Wide Bold */
@font-face {
	font-family: 'Staff Wide';
	src:
		url('./StaffWide-Bold.woff2') format('woff2'),
		url('./StaffWide-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
